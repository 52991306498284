<script>
import Layout from "@/router/layouts/auth";
import { mapState, mapActions } from "vuex";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
  },
  data() {
    return {
      loading: true,
      key: this.$route.params.token,
    };
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  methods: {
    ...mapActions("account", ["token"]),
    getToken() {
      const { key } = this;
      this.token({ key });
    },
  },
  mounted() {
    this.getToken();
  },
};
</script>

<template>
  <Layout>
    <div id="auth" class="row justify-content-center login-midlle-md">
      <div class="col-md-6 col-lg-5 col-xl-4 p-0">
        <div class="pt-5 pb-5 text-center">
          <router-link class="align-middle" tag="a" to="/">
            <img
              class="w-100"
              style="max-width: 100px"
              src="@/assets/images/logo-icon.png"
            />
          </router-link>
        </div>
        <div class="pt-5 pb-5 text-center">
          <div v-if="loading">
            <h5>Entrando</h5>
            <span
              aria-hidden="true"
              class="m-2 spinner-border text-dark"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
